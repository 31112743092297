import _toConsumableArray from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from '@guryou/hooks';
import { StateStatus } from '../core/StateStatus';

var defaultSelector = function defaultSelector() {
  return {
    status: StateStatus.NOT_INITIALIZED
  };
};

var useEffectOnLoading = function useEffectOnLoading(stateSelector, effect) {
  var deps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var dispatch = useDispatch();

  var _useSelector = useSelector(stateSelector || defaultSelector),
      data = _useSelector.data,
      status = _useSelector.status,
      version = _useSelector.version,
      entity = _useSelector.entity;

  useUpdateEffect(function () {
    if (status === StateStatus.LOADING) {
      effect();
    }
  }, [dispatch, status].concat(_toConsumableArray(deps)));
  return {
    data: data || [],
    status: status,
    version: version,
    entity: entity
  };
};

export default useEffectOnLoading;