import _taggedTemplateLiteral from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { sizes, spacer } from '@guryou/html-theme';
import styled from 'styled-components';
var FormTag = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  a {\n    margin: 0 auto;\n    width: 90%;\n    display: block;\n  }\n"])));
var FormActions = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  margin: ", "px auto;\n  text-align: center;\n  button {\n    display: flex;\n    width: 80%;\n    margin: ", "px auto;\n    max-width: 300px;\n  }\n"])), spacer.spacer4, spacer.spacer2);
var FormAvatarWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 90%;\n  margin: 0 auto;\n  max-width: ", "px;\n  display: flex;\n  margin-bottom: ", "px;\n  & img:hover {\n    opacity: 0.9;\n    cursor: pointer;\n  }\n"])), sizes.small, spacer.spacer2);
var HorizontalTimePickersWrapper = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  margin: 0 auto;\n\n  & .MuiFormControl-root {\n    width: auto;\n    margin: 0;\n    margin-left: ", "px;\n  }\n  & .MuiInput-root {\n    width: 6rem;\n  }\n"])), spacer.spacer2);
export { HorizontalTimePickersWrapper, FormAvatarWrapper, FormActions, FormTag };