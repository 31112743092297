import _objectWithoutProperties from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";
import { DEFAULT_SCALE } from '@guryou/html-theme';
import { paramCase } from 'change-case';
var breakpoints = ['xs', 'md', 'lg'];

var calculateTotalMargin = function calculateTotalMargin(props) {
  var margin = props.margin,
      marginLeft = props.marginLeft,
      marginRight = props.marginRight;
  var globalMargin = typeof margin !== 'string' && margin || 0;
  var totalMargin = typeof marginLeft !== 'string' && marginLeft || globalMargin;
  totalMargin += typeof marginRight !== 'string' && marginRight || globalMargin;
  return totalMargin * DEFAULT_SCALE;
};

var calculateSize = function calculateSize(val) {
  return Math.round(val / 12 * 10e7) / 10e5;
};

var toPixel = function toPixel(val) {
  var scale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return val && (typeof val === 'string' ? val : "".concat(val * scale, "px"));
};

var parseProperty = function parseProperty(key, value, obj) {
  var breakpoint = key.substring(0, 2);
  var result = {
    value: value,
    breakpoint: breakpoints.find(function (x) {
      return x === breakpoint;
    }) || 'xs'
  };
  var isKeyBreakpoint = breakpoints.some(function (x) {
    return x === key;
  });
  result.property = isKeyBreakpoint ? key : paramCase(key.replace(result.breakpoint, ''));
  var lowerCaseKey = result.property.toLocaleLowerCase();

  if (['xs-offset', 'offset'].includes(lowerCaseKey)) {
    result.property = 'margin-left';
    result.value = "".concat(calculateSize(value), "%");
    return result;
  }

  if (breakpoints.includes(lowerCaseKey)) {
    result.value = "calc(".concat(calculateSize(value), "% - ").concat(calculateTotalMargin(obj), "px)");
    result.property = 'width';
    return result;
  }

  if (typeof value === 'number') {
    if (lowerCaseKey.includes('width') || lowerCaseKey.includes('height')) {
      result.value = toPixel(value);
    } else if (lowerCaseKey.includes('padding') || lowerCaseKey.includes('margin') || lowerCaseKey === 'borderRadius') {
      result.value = toPixel(value, DEFAULT_SCALE);
    }
  }

  return result;
};

var filterProps = function filterProps(key, obj) {
  // allow only strings and numbers
  if (!['number', 'string'].includes(typeof obj[key])) {
    return false;
  }

  var blackListProps = ['id', 'className', 'children', 'text', 'variant', 'forwardedComponent', 'forwardedRef', 'theme', 'primary', 'secondary', 'default', 'info', 'warning', 'link', 'url', 'src'];
  return !blackListProps.includes(key) && !blackListProps.includes(obj[key]) && (obj[key] || obj[key] === 0);
};

var propsToCss = function propsToCss(props) {
  var onClick = props.onClick,
      rest = _objectWithoutProperties(props, ["onClick"]);

  var mediaGroups = {
    xs: {},
    md: {},
    lg: {}
  };
  Object.keys(rest).filter(function (key) {
    return filterProps(key, rest);
  }).sort().forEach(function (key) {
    var _parseProperty = parseProperty(key, rest[key], rest),
        breakpoint = _parseProperty.breakpoint,
        property = _parseProperty.property,
        value = _parseProperty.value;

    if (property === 'width' && mediaGroups[breakpoint][property]) {
      return;
    }

    mediaGroups[breakpoint][property] = value;
  });

  if (onClick) {
    mediaGroups.xs.cursor = 'pointer';
  }

  var result = "".concat(Object.keys(mediaGroups.xs).map(function (key) {
    return "".concat(key, ":").concat(mediaGroups.xs[key], " !important;");
  }).join(''));
  var mdKeys = Object.keys(mediaGroups.md);

  if (mdKeys.length) {
    result += "@media screen and (min-width: 768px){\n    ".concat(mdKeys.map(function (key) {
      return "".concat(key, ":").concat(mediaGroups.md[key], " !important;");
    }).join(''), "}");
  }

  var lgKeys = Object.keys(mediaGroups.lg);

  if (lgKeys.length) {
    result += "@media screen and (min-width: 1216px){\n    ".concat(lgKeys.map(function (key) {
      return "".concat(key, ":").concat(mediaGroups.lg[key], " !important;");
    }).join(''), "}");
  }

  return result;
};

export default propsToCss;
export { toPixel, calculateSize as mapXsToWidth, calculateTotalMargin };