import _taggedTemplateLiteral from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject;

import { Link } from 'react-router-dom';
import styled from 'styled-components';
import propsToCss from '../utils/propsToCss';
var RouterLink = styled(Link)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return propsToCss(props);
});
export { RouterLink };