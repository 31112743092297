import _objectSpread from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _taggedTemplateLiteral from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { colors } from '@guryou/colors';
import { typographyOverrides } from '@guryou/html-theme';
import styled from 'styled-components';
import { LinkButton } from '../buttons';
import propsToCss from '../utils/propsToCss';
var DefaultText = styled.p(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return propsToCss(_objectSpread(_objectSpread({}, typographyOverrides.MuiTypography.body1), props));
});
var MediumText = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return propsToCss(_objectSpread(_objectSpread({}, typographyOverrides.MuiTypography.h3), props));
});
var LargeText = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return propsToCss(_objectSpread(_objectSpread({}, typographyOverrides.MuiTypography.h2), props));
});
var SmallText = styled.p(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return propsToCss(_objectSpread(_objectSpread({}, typographyOverrides.MuiTypography.body2), props));
});
var SmallGrayText = styled(SmallText)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: ", ";\n"])), colors.grey);
var WarningText = styled(SmallText)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
var LinkText = styled(LinkButton)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n"])), function (props) {
  return propsToCss(_objectSpread({}, props));
});
export { DefaultText, MediumText, LargeText, SmallText, SmallGrayText, WarningText, LinkText };