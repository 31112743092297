import _toConsumableArray from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import { MODAL_CLOSE, MODAL_OPEN, MODAL_RESET } from './types';
var initialState = {
  data: [],
  version: 0
};
export default (function () {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case MODAL_OPEN:
      {
        var uniqueValues = state.data.filter(function (e) {
          return e.id !== action.payload.id;
        });
        return {
          data: [].concat(_toConsumableArray(uniqueValues), [action.payload]),
          version: +new Date()
        };
      }

    case MODAL_CLOSE:
      {
        if (action.payload.id) {
          return {
            data: state.data.filter(function (x) {
              return x.id !== action.payload.id;
            }),
            version: +new Date()
          };
        }

        state.data.pop();
        return {
          data: state.data,
          version: +new Date()
        };
      }

    case MODAL_RESET:
      return {
        data: [],
        version: +new Date()
      };

    default:
      return state;
  }
});