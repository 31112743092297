import _objectSpread from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
var initialState = {
  open: true
};

var blackFridayModalPopup = function blackFridayModalPopup() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case 'blackFriday':
      return _objectSpread(_objectSpread({}, state), {}, {
        open: action.value
      });

    default:
      return state;
  }
};

export { blackFridayModalPopup };