import _toConsumableArray from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import _objectSpread from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { ActionStatus, buildActionType, StateStatus } from '../core';
var initialState = {
  data: null,
  errors: null,
  status: StateStatus.NOT_INITIALIZED,
  version: 0,
  report: null
};

var topLevelServices = function topLevelServices(actionType) {
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    switch (action.type) {
      case buildActionType(actionType, ActionStatus.START):
        return _objectSpread(_objectSpread({}, initialState), {}, {
          status: StateStatus.LOADING,
          version: state.version,
          entity: actionType.entity
        });

      case buildActionType(actionType, ActionStatus.DONE):
        return {
          status: StateStatus.LOADED,
          data: action.payload,
          entity: actionType.entity,
          version: +new Date(),
          report: action.report
        };

      case buildActionType(actionType, ActionStatus.FAILED):
        return {
          status: StateStatus.ERROR,
          errors: action.payload,
          entity: actionType.entity
        };

      case buildActionType(actionType, ActionStatus.RESET):
        return initialState;

      case buildActionType(actionType, ActionStatus.UPDATE):
        return _objectSpread(_objectSpread({}, state), {}, {
          data: _toConsumableArray(action.newArr)
        });

      default:
        return state;
    }
  };
};

export { topLevelServices };