import _objectSpread from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { NOTIFICATION } from '../types';
var initialState = {
  message: null,
  variant: ''
};

var notification = function notification() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;

  switch (action.type) {
    case NOTIFICATION:
      return _objectSpread({}, action.payload);

    default:
      return state;
  }
};

export { notification };