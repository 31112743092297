import _taggedTemplateLiteral from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";
import _objectWithoutProperties from "/Users/andrej/Projects/GY/guryou-mono/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

var _templateObject;

import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import propsToCss, { calculateTotalMargin, mapXsToWidth, toPixel } from '../utils/propsToCss';
var BUTTONS_DEFAULT_SCALE = 6;

var handleProps = function handleProps(props) {
  var width = props.width,
      xs = props.xs,
      margin = props.margin,
      marginLeft = props.marginLeft,
      marginRight = props.marginRight,
      marginTop = props.marginTop,
      marginBottom = props.marginBottom,
      padding = props.padding,
      paddingLeft = props.paddingLeft,
      paddingRight = props.paddingRight,
      paddingTop = props.paddingTop,
      paddingBottom = props.paddingBottom,
      color = props.color,
      float = props.float,
      rest = _objectWithoutProperties(props, ["width", "xs", "margin", "marginLeft", "marginRight", "marginTop", "marginBottom", "padding", "paddingLeft", "paddingRight", "paddingTop", "paddingBottom", "color", "float"]);

  var cssProps = {
    float: float,
    margin: toPixel(margin || 1, BUTTONS_DEFAULT_SCALE),
    marginLeft: toPixel(marginLeft, BUTTONS_DEFAULT_SCALE),
    marginRight: toPixel(marginRight, BUTTONS_DEFAULT_SCALE),
    marginTop: toPixel(marginTop, BUTTONS_DEFAULT_SCALE),
    marginBottom: toPixel(marginBottom, BUTTONS_DEFAULT_SCALE)
  };
  padding && (cssProps.padding = toPixel(padding, BUTTONS_DEFAULT_SCALE));
  paddingLeft && (cssProps.paddingLeft = toPixel(paddingLeft, BUTTONS_DEFAULT_SCALE));
  paddingRight && (cssProps.paddingRight = toPixel(paddingRight, BUTTONS_DEFAULT_SCALE));
  paddingTop && (cssProps.paddingTop = toPixel(paddingTop, BUTTONS_DEFAULT_SCALE));
  paddingBottom && (cssProps.paddingBottom = toPixel(paddingBottom, BUTTONS_DEFAULT_SCALE));

  if (width) {
    cssProps.width = width;
  }

  if (xs) {
    var totalMargin = calculateTotalMargin({
      margin: margin || 1,
      marginLeft: marginLeft,
      marginRight: marginRight
    });
    cssProps.width = "calc(".concat(mapXsToWidth(xs), "% - ").concat(totalMargin, "px)");
  }

  if (!(color !== null && color !== void 0 && color.startsWith('#'))) {
    rest.color = color || 'default';
  } else {
    cssProps.color = color;
  }

  rest.style = cssProps;
  return rest;
};

var basePropTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  color: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
  action: PropTypes.string,
  entity: PropTypes.string
};
var StyledButton = styled(Button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n"])), function (props) {
  return propsToCss(props);
});
export { handleProps, basePropTypes, StyledButton };